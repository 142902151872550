// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-discovery-tsx": () => import("./../../../src/pages/discovery.tsx" /* webpackChunkName: "component---src-pages-discovery-tsx" */),
  "component---src-pages-thanks-tsx": () => import("./../../../src/pages/thanks.tsx" /* webpackChunkName: "component---src-pages-thanks-tsx" */),
  "component---src-pages-tools-tsx": () => import("./../../../src/pages/tools.tsx" /* webpackChunkName: "component---src-pages-tools-tsx" */),
  "component---src-templates-board-page-tsx": () => import("./../../../src/templates/board-page.tsx" /* webpackChunkName: "component---src-templates-board-page-tsx" */),
  "component---src-templates-index-page-tsx": () => import("./../../../src/templates/index-page.tsx" /* webpackChunkName: "component---src-templates-index-page-tsx" */),
  "component---src-templates-plain-page-tsx": () => import("./../../../src/templates/plain-page.tsx" /* webpackChunkName: "component---src-templates-plain-page-tsx" */),
  "component---src-templates-tool-page-tsx": () => import("./../../../src/templates/tool-page.tsx" /* webpackChunkName: "component---src-templates-tool-page-tsx" */),
  "component---src-templates-welcome-page-tsx": () => import("./../../../src/templates/welcome-page.tsx" /* webpackChunkName: "component---src-templates-welcome-page-tsx" */)
}

